import {
  SEARCH_SET_COMPANIES_SEARCH_QUERY,
  SEARCH_SET_DEVICES_SEARCH_QUERY,
  SEARCH_SET_GATEWAYS_SEARCH_QUERY,
  SEARCH_SET_USERS_SEARCH_QUERY,
  SetCompaniesSearchQuery,
  SetDevicesSearchQuery,
  SetGatewaysSearchQuery,
  SetUsersSearchQuery,
} from './actionTypes'

export const setUsersSearchQuery = (query: string): SetUsersSearchQuery => ({
  type: SEARCH_SET_USERS_SEARCH_QUERY,
  query,
})

export const setCompaniesSearchQuery = (query: string): SetCompaniesSearchQuery => ({
  type: SEARCH_SET_COMPANIES_SEARCH_QUERY,
  query,
})

export const setDevicesSearchQuery = (query: string): SetDevicesSearchQuery => ({
  type: SEARCH_SET_DEVICES_SEARCH_QUERY,
  query,
})

export const setGatewaysSearchQuery = (query: string): SetGatewaysSearchQuery => ({
  type: SEARCH_SET_GATEWAYS_SEARCH_QUERY,
  query,
})
