import React from 'react'
import { connect, ConnectedProps } from 'react-redux'

import Error403 from '../../components/Error403'
import getDisplayName from '../getDisplayName'

import { AppState } from '../../redux'
import { UserRole } from '../../types'

const mapState = (state: AppState) => ({
  userState: state.user,
})

const withUser = connect(mapState)

type WithUserProps = ConnectedProps<typeof withUser>

interface Props extends WithUserProps {
  roles: UserRole[]
  children: JSX.Element
}

export const RolesGuard = withUser(
  (props: Props): JSX.Element => {
    const {
      userState: { user },
      children,
      roles,
    } = props

    const hasRoles = () => {
      if (user) {
        const authorized = roles.reduce((acc, role) => {
          if (user.roles && user.roles.indexOf(role) !== -1) return true
          return acc
        }, false)
        return authorized
      }

      return false
    }

    return hasRoles() ? children : <Error403 />
  },
)

// eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/explicit-module-boundary-types
const withRolesGuard = (roles: UserRole[]) => <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  const WithRolesGuard = (props: P) => {
    return (
      <RolesGuard roles={roles}>
        <WrappedComponent {...props} />
      </RolesGuard>
    )
  }

  WithRolesGuard.displayName = `WithRolesGuard(${getDisplayName(WrappedComponent)})`

  return WithRolesGuard
}

export default withRolesGuard
