import React from 'react'
import { Route, RouteComponentProps, Switch, Redirect, withRouter } from 'react-router-dom'
import loadable from '@loadable/component'

const AsyncDashboard = loadable(() => import('../../modules/Dashboard'))
const AsyncUsers = loadable(() => import('../../modules/Users'))
const AsyncCompanies = loadable(() => import('../../modules/Companies'))
const AsyncDevices = loadable(() => import('../../modules/Devices'))
const AsyncGateways = loadable(() => import('../../modules/Gateways'))

const Routing = (props: RouteComponentProps) => {
  const { match } = props

  return (
    <Switch>
      <Route path={`${match.path}/dashboard`} component={AsyncDashboard} />
      <Route path={`${match.path}/users`} component={AsyncUsers} />
      <Route path={`${match.path}/companies`} component={AsyncCompanies} />
      <Route path={`${match.path}/devices`} component={AsyncDevices} />
      <Route path={`${match.path}/gateways`} component={AsyncGateways} />
      <Redirect to={`${match.path}/dashboard`} />
    </Switch>
  )
}

export default withRouter(Routing)
