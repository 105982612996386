import { ThunkAction } from 'redux-thunk'

import { AppState } from '../'
import {
  LoginLoadedErrorAction,
  DetailLoadedSuccessAction,
  DetailLoadedErrorAction,
  USER_LOGIN_LOADED_ERROR,
  USER_LOGIN_LOADING,
  USER_SIGN_OUT,
  USER_DETAIL_LOADED_SUCCESS,
  USER_DETAIL_LOADED_ERROR,
  UserActionTypes,
} from './actionTypes'

import { User } from '../../types'
import { URL_POST_LOGIN, URL_GET_ME } from './urls'
import { rest, restWithToken, RestParams } from '../../utils/rest'

import { AuthCookieService } from '../../utils/auth'

type Thunk<R> = ThunkAction<R, AppState, null, UserActionTypes>

const loginError = (): LoginLoadedErrorAction => ({
  type: USER_LOGIN_LOADED_ERROR,
})

const detailSuccess = (user: User): DetailLoadedSuccessAction => ({
  type: USER_DETAIL_LOADED_SUCCESS,
  user,
})

const detailError = (): DetailLoadedErrorAction => ({
  type: USER_DETAIL_LOADED_ERROR,
})

export const getMe = (): Thunk<void> => async (dispatch) => {
  try {
    const params: RestParams = {
      url: URL_GET_ME,
    }

    const res = await restWithToken(params)
    if (res.status === 200) {
      const json = await res.json()
      dispatch(detailSuccess(json))
      return
    }
  } catch (error) {
    console.error(error)
  }

  dispatch(detailError())
}

export const login = (username: string, password: string): Thunk<void> => async (dispatch) => {
  dispatch({
    type: USER_LOGIN_LOADING,
  })

  try {
    const body = JSON.stringify({
      grantType: 'password',
      email: username,
      password,
    })

    const params: RestParams = {
      url: URL_POST_LOGIN,
      method: 'POST',
      body,
    }

    const res = await rest(params)
    if (res.status === 200) {
      const { accessToken } = await res.json()
      AuthCookieService.storeAuthInfo(accessToken, true)
      dispatch(getMe())
      return
    }
  } catch (error) {
    console.error(error)
  }

  dispatch(loginError())
}

export const loginWithSession = (): Thunk<void> => async (dispatch) => {
  dispatch({
    type: USER_LOGIN_LOADING,
  })

  const authInfos = AuthCookieService.getAuthInfo()
  if (authInfos && authInfos.token) {
    dispatch(getMe())
  } else {
    dispatch(detailError())
  }
}

export const signOut = (): Thunk<void> => async (dispatch) => {
  AuthCookieService.clearAuthInfo()

  dispatch({
    type: USER_SIGN_OUT,
  })
}
