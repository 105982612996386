import { APP_TOGGLE_MENU, AppActionTypes } from './actionTypes'

export interface AppState {
  openMenu: boolean
}

const defaultState: AppState = {
  openMenu: window.innerWidth <= 1100 ? false : true,
}

const app = (state: AppState = defaultState, action: AppActionTypes): AppState => {
  switch (action.type) {
    case APP_TOGGLE_MENU:
      return { ...state, openMenu: !state.openMenu }
    default:
      return state
  }
}

export default app
