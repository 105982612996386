import React from 'react'

import { withLogin, WithLoginProps } from './Auth.container'

export class Auth extends React.Component<WithLoginProps> {
  componentDidMount(): void {
    const { loginWithSession } = this.props
    loginWithSession()
  }

  render(): React.ReactNode {
    const { children } = this.props
    return children
  }
}

export default withLogin(Auth)
