import React from 'react'
import { Redirect, Route, RouteComponentProps } from 'react-router-dom'
import { withTranslation, WithTranslation } from 'react-i18next'

import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/fr'

import { configurei18n, Language, languages } from '../'

class Translations extends React.Component<RouteComponentProps> {
  constructor(props: Props) {
    super(props)

    const language = this.getLanguage()
    configurei18n(language)

    moment.locale(language, {
      week: {
        dow: 1,
        doy: 1,
      },
    })
  }

  getLanguage = (): Language => {
    const { location } = this.props
    const language = location.pathname.split('/')[1] || (navigator.language || '').split('-')[0]
    const defaultLanguage = (languages.indexOf(language as any) !== -1 ? language : 'en') as Language

    return defaultLanguage
  }

  languageHandled = (): boolean => {
    const { location } = this.props
    const language = location.pathname.split('/')[1]

    return languages.indexOf(language as any) !== -1
  }

  render(): React.ReactNode {
    const { children } = this.props
    const language = this.getLanguage()

    if (!this.languageHandled()) {
      return <Redirect to={`/${language}`} />
    }

    return (
      <React.Fragment>
        <LanguageFixerHOC {...this.props} language={language} />
        <Route path={`/:language`} render={() => children} />
      </React.Fragment>
    )
  }
}

type Props = RouteComponentProps &
  WithTranslation & {
    language: string
  }

class LanguageFixer extends React.Component<Props> {
  componentDidUpdate() {
    const { i18n, language } = this.props
    if (i18n.language !== language) {
      i18n.changeLanguage(language)
    }
  }

  render(): JSX.Element | null {
    return null
  }
}

const LanguageFixerHOC = withTranslation()(LanguageFixer)

export default Translations
