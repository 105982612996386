import styled from 'styled-components'

export const Container = styled.div<{ selected: boolean; hover: boolean; hideOnMobile?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  background-color: ${({ selected, hover }) => {
    if (selected) return '#1d56a7'
    if (hover) return '#4270B2'
  }};
  color: white;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'flex')};
  }
`

export const IconContainer = styled.div`
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`
