import styled from 'styled-components'

export const Container = styled.div<{ open: boolean }>`
  overflow: hidden;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.main};
  transition: width 0.3s, min-width 0.3s, left 0.3s;
  width: ${({ open }) => (open ? 100 : 0)}px;
  min-width: ${({ open }) => (open ? 100 : 0)}px;
  position: relative;

  @media screen and (max-width: 1100px) {
    position: absolute;
    z-index: 2;
    left: ${({ open }) => (open ? 0 : -100)}px;
    width: 100px;
    min-width: 100px;
  }
`

export const ListContainer = styled.div`
  width: 100%;
  height: 100%;
  width: 100px;
`

export const HideContainer = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
`

export const SelectContainer = styled.div`
  user-select: none;
  cursor: default;
  color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 10px;

  @media (max-width: ${({ theme }) => theme.responsive.mobile.valueString}) {
    bottom: 70px;
    justify-content: flex-start;
    padding: 0 30px;
  }
`

export const Select = styled.select`
  margin-left: 10px;
  font-weight: bold;
  font-size: 0.8rem;
  border: none;
  outline: none;
  appearance: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.main};
`
