import React from 'react'
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom'
import loadable from '@loadable/component'

import Main from '../Main'

const AsyncSignIn = loadable(() => import('../../modules/SignIn'))

const Routing = (props: RouteComponentProps) => {
  const { match } = props

  return (
    <Switch>
      <Route path={`${match.path}/sign-in`} component={AsyncSignIn} />$
      <Route path={match.path} component={Main} />
    </Switch>
  )
}

export default withRouter(Routing)
