import { connect, ConnectedProps } from 'react-redux'

import { Actions, AppState } from '../../../redux'

const mapState = (state: AppState) => ({
  menuOpen: state.app.openMenu,
})

const mapDispatch = {
  toggleMenu: Actions?.app.toggleMenu,
}

export const withSideBar = connect(mapState, mapDispatch)

export type WithSideBarProps = ConnectedProps<typeof withSideBar>
