import React from 'react'

import { Container, IconContainer } from './Item.styled'

import { SvgIconProps } from '@material-ui/core/SvgIcon'

interface Props {
  Icon: React.ComponentType<SvgIconProps>
  selected: boolean
  onClick: () => void
  hideOnMobile?: boolean
}

class Item extends React.Component<Props> {
  public state = {
    hover: false,
  }

  public toggleHover = (): void => {
    const { hover } = this.state
    this.setState({ hover: !hover })
  }

  public render(): React.ReactNode {
    const { Icon, onClick, selected, hideOnMobile } = this.props
    const { hover } = this.state

    return (
      <Container
        selected={selected}
        hover={hover}
        onClick={onClick}
        onMouseEnter={this.toggleHover}
        onMouseLeave={this.toggleHover}
        hideOnMobile={hideOnMobile}
      >
        <IconContainer>
          <Icon style={{ fontSize: 40 }} />
        </IconContainer>
      </Container>
    )
  }
}

export default Item
