import React from 'react'
import { CircularProgress } from '@material-ui/core'

import { Container } from './Loader.styled'

interface Props {
  size?: number
}

const Loader = (props: Props): JSX.Element => {
  const { size = 40 } = props
  return (
    <Container>
      <CircularProgress color="inherit" size={size} />
    </Container>
  )
}

export default Loader
