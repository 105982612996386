import React, { useEffect } from 'react'
import ReactGA, { FieldsObject } from 'react-ga'
import { RouteComponentProps } from 'react-router-dom'

const { REACT_APP_GOOGLE_ANALYTICS_UA } = process.env

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const withTracker = <P extends RouteComponentProps>(
  WrappedComponent: React.ComponentType<P>,
  options: FieldsObject = {},
) => {
  const trackPage = (page: string) => {
    if (REACT_APP_GOOGLE_ANALYTICS_UA) {
      ReactGA.set({ page, ...options })
      ReactGA.pageview(page)
    }
  }

  // eslint-disable-next-line react/display-name
  return (props: P) => {
    useEffect(() => {
      trackPage(props.location.pathname)
    }, [props.location.pathname])

    return <WrappedComponent {...props} />
  }
}
