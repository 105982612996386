import { connect, ConnectedProps } from 'react-redux'

import { Actions } from '../../../redux'

const mapDispatch = {
  loginWithSession: Actions?.user.loginWithSession,
}

export const withLogin = connect(undefined, mapDispatch)

export type WithLoginProps = ConnectedProps<typeof withLogin>
