import { ApolloClient, createHttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import { AuthCookieService } from '../utils/auth'

export type Client = ApolloClient<NormalizedCacheObject>

export const getApiClient = (): ApolloClient<NormalizedCacheObject> => {
  const cache = new InMemoryCache()

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_GRAPHQL_ENDPOINT,
  })

  const authLink = setContext(async (_, { headers }) => {
    const token = AuthCookieService.getAuthInfo()?.token

    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    }
  })
  return new ApolloClient({
    cache,
    link: authLink.concat(httpLink),
  })
}
