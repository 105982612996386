import { SignOutAction } from '../user/actionTypes'

export const SEARCH_SET_USERS_SEARCH_QUERY = '@@search/SET_USERS_SEARCH_QUERY'

export interface SetUsersSearchQuery {
  type: typeof SEARCH_SET_USERS_SEARCH_QUERY
  query: string
}

export const SEARCH_SET_COMPANIES_SEARCH_QUERY = '@@search/SET_COMPANIES_SEARCH_QUERY'

export interface SetCompaniesSearchQuery {
  type: typeof SEARCH_SET_COMPANIES_SEARCH_QUERY
  query: string
}

export const SEARCH_SET_DEVICES_SEARCH_QUERY = '@@search/SET_DEVICES_SEARCH_QUERY'

export interface SetDevicesSearchQuery {
  type: typeof SEARCH_SET_DEVICES_SEARCH_QUERY
  query: string
}

export const SEARCH_SET_GATEWAYS_SEARCH_QUERY = '@@search/SEARCH_SET_GATEWAYS_SEARCH_QUERY'

export interface SetGatewaysSearchQuery {
  type: typeof SEARCH_SET_GATEWAYS_SEARCH_QUERY
  query: string
}

export type AppActionTypes =
  | SignOutAction
  | SetUsersSearchQuery
  | SetCompaniesSearchQuery
  | SetDevicesSearchQuery
  | SetGatewaysSearchQuery
