import { USER_SIGN_OUT } from '../user/actionTypes'
import {
  AppActionTypes,
  SEARCH_SET_COMPANIES_SEARCH_QUERY,
  SEARCH_SET_DEVICES_SEARCH_QUERY,
  SEARCH_SET_GATEWAYS_SEARCH_QUERY,
  SEARCH_SET_USERS_SEARCH_QUERY,
} from './actionTypes'

export interface SearchState {
  user: {
    query: string
  }
  company: {
    query: string
  }
  device: {
    query: string
  }
  gateway: {
    query: string
  }
}

const defaultState: SearchState = {
  user: {
    query: '',
  },
  company: {
    query: '',
  },
  device: {
    query: '',
  },
  gateway: {
    query: '',
  },
}

const search = (state: SearchState = defaultState, action: AppActionTypes): SearchState => {
  switch (action.type) {
    case SEARCH_SET_USERS_SEARCH_QUERY:
      return {
        ...state,
        user: {
          query: action.query,
        },
      }

    case SEARCH_SET_COMPANIES_SEARCH_QUERY:
      return {
        ...state,
        company: {
          query: action.query,
        },
      }

    case SEARCH_SET_DEVICES_SEARCH_QUERY:
      return {
        ...state,
        device: {
          query: action.query,
        },
      }

    case SEARCH_SET_GATEWAYS_SEARCH_QUERY:
      return {
        ...state,
        device: {
          query: action.query,
        },
      }

    case USER_SIGN_OUT:
      return defaultState
    default:
      return state
  }
}

export default search
