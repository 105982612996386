import React from 'react'
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'

import Loader from '../../components/Loader'
import getDisplayName from '../getDisplayName'

import { AppState } from '../../redux'

const mapState = (state: AppState) => ({
  userState: state.user,
})

const withUser = connect(mapState)

type WithUserProps = ConnectedProps<typeof withUser>

interface Props extends WithUserProps, RouteComponentProps {
  children?: JSX.Element
}

export const AuthGuard = withRouter(
  withUser(
    (props: Props): JSX.Element => {
      const {
        userState: { user, loading },
        location,
        children,
      } = props

      const { i18n } = useTranslation()

      return (
        <React.Fragment>
          {loading === 1 ? (
            <Loader />
          ) : user ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: `/${i18n.language}/sign-in`,
                state: { from: location },
              }}
            />
          )}
        </React.Fragment>
      )
    },
  ),
)

// eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/explicit-module-boundary-types
const withAuthGuard = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  const WithAuthGuard = (props: P) => {
    return (
      <AuthGuard>
        <WrappedComponent {...props} />
      </AuthGuard>
    )
  }

  WithAuthGuard.displayName = `WithAuthGuard(${getDisplayName(WrappedComponent)})`

  return WithAuthGuard
}

export default withAuthGuard
