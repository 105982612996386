import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import SignOutIcon from '@material-ui/icons/PowerSettingsNew'

import { withHeader, WithHeaderProps } from './Header.container'

import { Container, LogoContainer, ToolbarContainer, Title, UserContainer } from './Header.styled'

export const Header = (props: WithHeaderProps): JSX.Element => {
  const {
    userState: { user },
    signOut,
    toggleMenu,
  } = props

  return (
    <Container>
      <LogoContainer onClick={toggleMenu} />
      <ToolbarContainer>
        <Title>Telaqua Backoffice</Title>
        <UserContainer>
          {user && `${user.firstName} ${user.lastName}`}
          <IconButton style={{ marginLeft: 10 }} color="inherit" onClick={signOut}>
            <SignOutIcon />
          </IconButton>
        </UserContainer>
      </ToolbarContainer>
    </Container>
  )
}

export default withHeader(Header)
