const AUTH_COOKIE_NAME = 'authInfo'
const REMEMBER_ME_COOKIE_NAME = 'rememberMe'

class AuthCookieService {
  /**
   * Store authentication information to cookie
   */
  public static storeAuthInfo(token: string, persistent: boolean): void {
    const duration = (persistent ? process.env.REACT_APP_AUTH_COOKIE_DURATION : 0) as number
    AuthCookieService.store(AUTH_COOKIE_NAME, { token }, duration)
  }

  /**
   * Get authentication information from cookie
   */
  public static getAuthInfo(): { token: string } | undefined {
    return AuthCookieService.get(AUTH_COOKIE_NAME)
  }

  /**
   * Clear authentication information cookie
   */
  public static clearAuthInfo(): void {
    const d: Date = new Date()
    d.setTime(0)
    document.cookie = `${AUTH_COOKIE_NAME}=;expires=${d.toUTCString()};path=/`
  }

  /**
   * Store remember me information to cookie
   */
  public static storeRememberMe(rememberMe: boolean): void {
    AuthCookieService.store(REMEMBER_ME_COOKIE_NAME, rememberMe, 10 * 365 * 86400)
  }

  /**
   * Get remember me information from cookie
   */
  public static getRememberMe(): any {
    return AuthCookieService.get(REMEMBER_ME_COOKIE_NAME)
  }

  /**
   * Generic value store to cookie
   */
  private static store(cookieName: string, value: any, duration: number) {
    const cookieValue = JSON.stringify(value)

    let expires = ''
    if (duration) {
      const d: Date = new Date()
      d.setTime(d.getTime() + duration * 1000)
      expires = `expires=${d.toUTCString()};`
    }

    document.cookie = `${cookieName}=${cookieValue};${expires}path=/`
  }

  /**
   * Generic value get from cookie
   */
  private static get(cookieName: string): any {
    const name = `${cookieName}=`
    const decodedCookies: string[] = decodeURIComponent(document.cookie).split(';')

    for (const decodedCookie of decodedCookies) {
      let c: string = decodedCookie
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }

      // TODO proper error management
      if (c.indexOf(name) === 0) {
        const valueString = c.substring(name.length, c.length)

        return JSON.parse(valueString)
      }
    }
    return null
  }
}

export default AuthCookieService
