import styled from 'styled-components'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
`

export const AppContainer = styled.div`
  position: relative;
  height: calc(100% - 57px);
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  @media (min-width: 0px) and (orientation: landscape) {
    height: calc(100% - 49px);
  }

  @media (min-width: 600px) {
    height: calc(100% - 65px);
  }
`

export const MainContainer = styled.main`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding-top: 16px;
  -webkit-overflow-scrolling: touch;
`
