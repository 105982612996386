import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import BusinessIcon from '@material-ui/icons/BusinessRounded'
import DashboardIcon from '@material-ui/icons/DashboardRounded'
import GroupIcon from '@material-ui/icons/GroupRounded'
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna'
import TapAndPlayIcon from '@material-ui/icons/TapAndPlayRounded'

import selectLanguages from '../../../assets/selectLanguages.png'
import Item from './Item'
import { withSideBar, WithSideBarProps } from './SideBar.container'
import { Container, HideContainer, ListContainer, Select, SelectContainer } from './SideBar.styled'

interface Props extends RouteComponentProps, WithTranslation, WithSideBarProps {
  menuOpen: boolean
}

export const SideBar = (props: Props): JSX.Element => {
  const {
    menuOpen,
    location: { pathname },
    history: { push },
    toggleMenu,
    i18n,
  } = props

  const { language } = i18n

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const path = pathname.replace(`/${i18n.language}/`, `/${event.target.value}/`)
    push(path)
  }

  const items = [
    {
      path: `/${i18n.language}/dashboard`,
      Icon: DashboardIcon,
    },
    {
      path: `/${i18n.language}/users`,
      Icon: GroupIcon,
    },
    {
      path: `/${i18n.language}/companies`,
      Icon: BusinessIcon,
    },
    {
      path: `/${i18n.language}/devices`,
      Icon: TapAndPlayIcon,
    },
    {
      path: `/${i18n.language}/gateways`,
      Icon: SettingsInputAntennaIcon,
    },
  ]

  const onClick = (path: string): void => {
    push(path)
    if (window.innerWidth <= 1100) {
      toggleMenu()
    }
  }

  return (
    <React.Fragment>
      {menuOpen && window.innerWidth <= 1100 && <HideContainer onClick={toggleMenu} />}
      <Container open={menuOpen}>
        <ListContainer>
          {items.map(({ path, Icon }) => (
            <Item key={path} selected={pathname.startsWith(path)} onClick={() => onClick(path)} Icon={Icon} />
          ))}
        </ListContainer>
        <SelectContainer>
          <img src={selectLanguages} />
          <Select value={language} onChange={handleChange}>
            <option value="fr">FR</option>
            <option value="en">EN</option>
            <option value="es">ES</option>
          </Select>
        </SelectContainer>
      </Container>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(withSideBar(SideBar)))
