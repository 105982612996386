import styled from 'styled-components'
import logo from '../../../assets/logo.png'

export const Container = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px rgba(22, 38, 45, 0.4);
  position: relative;

  z-index: 11;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);

  @media (min-width: 0px) and (orientation: landscape) {
    height: 48px;
  }

  @media (min-width: 600px) {
    height: 64px;
  }
`

// Logo

export const LogoContainer = styled.div`
  height: 100%;
  width: 100px;
  min-width: 100px;
  background-color: ${({ theme }) => theme.colors.main};
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
  user-select: none;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30%;
`

// Toolbar

export const ToolbarContainer = styled.div`
  height: 100%;
  width: calc(100% - 100px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.main};
  padding: 0 15px;
  background-color: rgba(29, 86, 167, 0.2);
`

export const Title = styled.div`
  font-size: 22px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.secondary};
`

export const UserContainer = styled.div`
  user-select: none;
  cursor: default;
`
