import ReactGA from 'react-ga'
import * as Sentry from '@sentry/browser'

const { REACT_APP_SENTRY_DSN, REACT_APP_SENTRY_ENVIRONMENT, REACT_APP_GOOGLE_ANALYTICS_UA } = process.env

// Sentry

if (REACT_APP_SENTRY_DSN && REACT_APP_SENTRY_ENVIRONMENT) {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    environment: REACT_APP_SENTRY_ENVIRONMENT,
  })
}

// Google analytics

const initializeAnalytics = () => {
  if (REACT_APP_GOOGLE_ANALYTICS_UA) {
    ReactGA.initialize('UA-167084242-1')
  }
}

initializeAnalytics()

export * from './withTracker.component'
