import { User } from '../../types'

// Login

export const USER_LOGIN_LOADING = '@@user/LOGIN_LOADING'
export const USER_LOGIN_LOADED_ERROR = '@@user/LOGIN_LOADED_ERROR'

export interface LoginLoadingAction {
  type: typeof USER_LOGIN_LOADING
}

export interface LoginLoadedErrorAction {
  type: typeof USER_LOGIN_LOADED_ERROR
}

// Signout

export const USER_SIGN_OUT = '@@user/SIGN_OUT'

export interface SignOutAction {
  type: typeof USER_SIGN_OUT
}

// User details

export const USER_DETAIL_LOADED_SUCCESS = '@@user/DETAIL_LOADED_SUCCESS'
export const USER_DETAIL_LOADED_ERROR = '@@user/DETAIL_LOADED_ERROR'

export interface DetailLoadedSuccessAction {
  type: typeof USER_DETAIL_LOADED_SUCCESS
  user: User
}

export interface DetailLoadedErrorAction {
  type: typeof USER_DETAIL_LOADED_ERROR
}

export type UserActionTypes =
  | LoginLoadingAction
  | LoginLoadedErrorAction
  | SignOutAction
  | DetailLoadedSuccessAction
  | DetailLoadedErrorAction
