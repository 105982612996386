import React from 'react'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter as Router, Route, RouteComponentProps } from 'react-router-dom'
import { Provider } from 'react-redux'

import ErrorBoundary from '../../components/ErrorBoundary'
import Translation from '../../translations/Translation'
import Routing from './App.routing'
import { theme, GlobalStyle } from './theme'

import { Auth } from '../../utils/auth'

import { store } from '../../redux'

const App = (): JSX.Element => (
  <ErrorBoundary>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Router>
        <Route
          to="/"
          render={(RCProps: RouteComponentProps) => (
            <Translation {...RCProps}>
              <Provider store={store}>
                <Auth>
                  <Routing />
                </Auth>
              </Provider>
            </Translation>
          )}
        />
      </Router>
    </ThemeProvider>
  </ErrorBoundary>
)

export default App
