import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from './en.json'
import fr from './fr.json'
import es from './es.json'

export type Language = 'en' | 'fr' | 'es'

export const languages: Language[] = ['en', 'fr', 'es']

export const configurei18n = (lng: Language): void => {
  i18next.use(initReactI18next).init({
    lng,
    // debug: true,
    resources: {
      en: {
        translation: en,
      },
      fr: {
        translation: fr,
      },
      es: {
        translation: es,
      },
    },
  })
}
